const ACCESS = {
  API: 'api',
  RAZORPAY: 'razorpay',
};

const API_ENV = {
  DEV: 'https://api-dev-btoc-vitals.carenow.healthcare',
  STAGING: 'https://api-staging-vitals.carenow.healthcare',
  PROD: 'https://api-demo-vitals.carenow.healthcare',
};

const RAZORPAY_ENV = {
  DEV: 'rzp_test_6gwhNOZlsbqwq7',
  STAGING: 'rzp_test_6gwhNOZlsbqwq7',
  PROD: 'rzp_live_rtq3PaNcEKrmm6',
};

const accessValue = ({ access = ACCESS.API, env = 'DEV' }) => {
  switch (access) {
    case ACCESS.API:
      return API_ENV[env];

    case ACCESS.RAZORPAY:
      return RAZORPAY_ENV[env];

    default:
      return null;
  }
};

const env = ({ access = ACCESS.API }) => {
  if (process.env.REACT_APP_ENV) {
    return accessValue({ access, env: process.env.REACT_APP_ENV });
  }

  if (process.env.NODE_ENV === 'development') {
    return accessValue({ access, env: 'DEV' });
  }

  return null;
};

export default env;
export { ACCESS };

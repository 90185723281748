import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  fetchCreditsInfo,
  fetchCompleteUserDetails,
} from '../store/thunks/profile-detail-thunks';
import axios from '../axios';
import parseURL from '../utility/parse-URL';
import { uiActions } from '../store/reducers/ui-slice';

const Initialize = ({ children }) => {
  const dispatch = useDispatch();

  const {
    push,
    location: { search, pathname },
    replace,
    listen,
  } = useHistory();

  const [init, setInit] = useState(false);

  const oneTimeAfterLoginRef = useRef(false);
  const oneTimeAfterLogoutRef = useRef(true);

  const authToken = useSelector((state) => state.auth.authToken);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const redirectToUserLogin = useSelector(
    (state) => state.ui.redirectToUserLogin,
  );

  useEffect(() => {
    if (authToken) {
      axios.defaults.headers.common['Authorization'] = authToken;

      setInit(true);
      dispatch(fetchCreditsInfo({ fetchNewData: true }));
      dispatch(fetchCompleteUserDetails({}));
    }
  }, [authToken, dispatch]);

  // refreshing userDetails and creditsInfo on certain routes after initialization
  useEffect(() => {
    listen(({ pathname }) => {
      if (oneTimeAfterLoginRef.current) {
        if (
          ['/dashboard', '/buy-credits', '/credit-history']
            .map((item) => `/user${item}`)
            .includes(pathname)
        ) {
          dispatch(fetchCreditsInfo({ fetchNewData: true }));
        }

        if (
          (['/redeem-credit'].includes(pathname) ||
            pathname.startsWith('/passes/')) &&
          isLoggedIn
        ) {
          dispatch(fetchCreditsInfo({ fetchNewData: true }));
        }

        if (
          ['/dashboard', '/checkout', '/profile']
            .map((item) => `/user${item}`)
            .includes(pathname)
        ) {
          dispatch(fetchCompleteUserDetails({}));
        }
      }
    });
  }, [dispatch, listen, isLoggedIn]);

  useEffect(() => {
    if (isLoggedIn && !oneTimeAfterLoginRef.current) {
      oneTimeAfterLoginRef.current = true;
      oneTimeAfterLogoutRef.current = false;
      const redirectURL = parseURL(search, 'redirect');

      if (redirectURL) {
        push(`/${redirectURL}`);
      }
    } else if (!isLoggedIn && !oneTimeAfterLogoutRef.current) {
      oneTimeAfterLoginRef.current = false;
      oneTimeAfterLogoutRef.current = true;

      // if (
      //   [
      //     '/dashboard',
      //     '/buy-credits',
      //     '/credit-history',
      //     '/my-bookings',
      //     '/profile',
      //     '/vitals-history',
      //     '/vitals-list',
      //     '/report',
      //     '/checkout',
      //   ]
      //     .map((item) => `/user${item}`)
      //     .includes(pathname)
      // ) {
      //   push('/');
      // }
      if (redirectToUserLogin) {
        replace('/user/login');
        dispatch(uiActions.setRedirectToUserLogin());
      }
    }
  }, [
    isLoggedIn,
    search,
    push,
    pathname,
    redirectToUserLogin,
    dispatch,
    replace,
  ]);

  return !isLoggedIn ? children : init ? children : null;
};

export default Initialize;

const parseURL = (search, findKey) => {
  const urlParams = new URLSearchParams(search);
  const searchParamsEntries = urlParams.entries();

  const list = [];

  if (search) {
    for (const [key, value] of searchParamsEntries) {
      if (findKey) {
        if (key === findKey && !!value) return decodeURIComponent(value);
      } else if (!!key && !!value) {
        list.push({ key, value: decodeURIComponent(value) });
      }
    }
  }

  return findKey ? '' : list;
};

const makeAnURLWithoutAKey = (search, findKey) => {
  const urlParams = new URLSearchParams(search);
  const searchParamsEntries = urlParams.entries();

  let list = [];

  if (search) {
    for (const [key, value] of searchParamsEntries) {
      if (key !== findKey) {
        list.push(`${key}=${value}`);
      }
    }
  }

  return `?${list.join('&')}`;
};

export { makeAnURLWithoutAKey };

export default parseURL;

import React, { useEffect, lazy, Suspense } from 'react';
import { createTheme, ThemeProvider, CssBaseline } from '@material-ui/core';
import { Route, Redirect, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import CryptoJS from 'crypto-js';

import Initialize from './hoc/Initialize';
import SnackBarHOC from './hoc/SnackBarHOC';
import HandleError from './hoc/HandleError';
import Header from './containers/Header';
import {
  loginUsingSavedCredentials,
  loginUsingAuthToken,
  logout,
} from './store/thunks/auth-thunks';
import RouteLoader from './components/RouteLoader';
import ProtectedRoute from './hoc/ProtectedRoute';
import LoggedOutRoute from './hoc/LoggedOutRoute';
import TransitionSwitch from './hoc/TransitionSwitch';
import useDialog from './hooks/useDialog';
import parseURL, { makeAnURLWithoutAKey } from './utility/parse-URL';
import { ACCESS_TOKEN } from './global-constants';
import globalTheme from './theme';

const Page = lazy(() => import('./hoc/Page'));

const Dashboard = lazy(() => import('./pages/Dashboard'));
const Login = lazy(() => import('./pages/Login'));
const Home = lazy(() => import('./pages/Home'));
const RedeemCredit = lazy(() => import('./pages/RedeemCredit'));
const BuyCredit = lazy(() => import('./pages/BuyCredit'));
const CreditHistory = lazy(() => import('./pages/CreditHistory'));
const Bookings = lazy(() => import('./pages/Bookings'));
const Profile = lazy(() => import('./pages/Profile'));
const Search = lazy(() => import('./pages/Search'));
const FindDoctor = lazy(() => import('./pages/FindDoctor'));
const Register = lazy(() => import('./pages/Register'));
const Vitals = lazy(() => import('./pages/Vitals'));
const About = lazy(() => import('./pages/About'));
const TravellingDuringCovid = lazy(() =>
  import('./pages/TravellingDuringCovid'),
);
const ThirdWave = lazy(() => import('./pages/ThirdWave'));
const Contact = lazy(() => import('./pages/Contact'));
const Career = lazy(() => import('./pages/Career'));
const Investor = lazy(() => import('./pages/Investor'));
const Healthpass = lazy(() => import('./pages/Healthpass'));
const ForgotPassword = lazy(() => import('./pages/ForgotPassword'));
const DoctorDetails = lazy(() => import('./pages/DoctorDetails'));
const HistoricData = lazy(() => import('./pages/HistoricData'));
const VitalsList = lazy(() => import('./pages/VitalsList'));
const Report = lazy(() => import('./pages/Report'));
const FAQ = lazy(() => import('./pages/FAQ'));
const SDK = lazy(() => import('./pages/SDK'));
const Blogs = lazy(() => import('./pages/Blogs'));
const PassesDetails = lazy(() => import('./pages/PassesDetails'));
const Checkout = lazy(() => import('./pages/Checkout'));
const Page404 = lazy(() => import('./pages/Page404'));
const Terms = lazy(() => import('./pages/Terms'));
const FreeVaccine = lazy(() => import('./pages/FreeVaccine'));
const Policy = lazy(() => import('./pages/Policy'));
const KnowVitals = lazy(() => import('./pages/KnowVitals'));
const AdNetwork = lazy(() => import('./pages/AdNetwork'));
const TempHome = lazy(() => import('./pages/TempHome'));

const App = () => {
  const { networkFetching, loadingMessage } = useSelector((state) => state.ui);

  const { authToken } = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  const {
    push,
    location: { search, pathname },
  } = useHistory();

  const userInfo = parseURL(search, 'userinfo');

  useEffect(() => {
    if (userInfo) {
      const decryptedString = CryptoJS.AES.decrypt(
        decodeURIComponent(userInfo),
        'careplix-is-the-king',
      );

      const { user_id, id_token } = JSON.parse(
        decryptedString.toString(CryptoJS.enc.Utf8),
      );

      dispatch(loginUsingAuthToken({ user_id, id_token }));
    } else {
      dispatch(loginUsingSavedCredentials());
    }
  }, [dispatch, userInfo]);

  // Handling Multiple tab logouts
  useEffect(() => {
    const logoutNow = () => {
      if (!localStorage.getItem(ACCESS_TOKEN)) {
        dispatch(logout());
      }
    };

    window.onfocus = logoutNow;

    return () => {
      window.onfocus = null;
    };
  }, [dispatch]);

  // Handling Multiple tab logins
  useEffect(() => {
    const loginNow = () => {
      if (authToken !== localStorage.getItem(ACCESS_TOKEN)) {
        dispatch(loginUsingSavedCredentials());
      }
    };

    window.addEventListener('storage', loginNow);

    return () => {
      window.removeEventListener('storage', loginNow);
    };
  }, [dispatch, authToken]);

  // Handling Mobile-App Redirects or from other devices
  useEffect(() => {
    if (userInfo) {
      push({
        pathname: pathname,
        search: makeAnURLWithoutAKey(search, 'userinfo'),
      });
    }
  }, [userInfo, search, pathname, push]);

  const { dialogComponent, openDialog, closeDialog } = useDialog({
    loaderTemplate: true,
  });

  useEffect(() => {
    if (networkFetching) {
      openDialog(null, {
        autoCloseWhenBackdropClicked: false,
        icon: false,
        content: loadingMessage,
      });
    } else {
      closeDialog();
    }
  }, [networkFetching, loadingMessage, openDialog, closeDialog]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {dialogComponent}
      <Header />

      <SnackBarHOC>
        <HandleError>
          <Initialize>
            <Suspense fallback={<RouteLoader />}>
              <TransitionSwitch>
                <Route exact path="/home" component={Home} />
                <Route exact path="/vitals" component={Vitals} />
                <Route exact path="/about" component={About} />
                <Route exact path="/find-doctor" component={FindDoctor} />
                <Route exact path="/doctor-details" component={DoctorDetails} />
                <Route exact path="/contact" component={Contact} />
                <Route exact path="/career" component={Career} />
                <Route exact path="/investor" component={Investor} />
                <Route exact path="/healthpass" component={Healthpass} />
                <Route exact path="/faq" component={FAQ} />
                <Route exact path="/corporateapp" component={SDK} />
                <Route exact path="/blogs" component={Blogs} />
                <Route exact path="/terms" component={Terms} />
                <Route exact path="/free-vaccine" component={FreeVaccine} />
                <Route
                  exact
                  path="/travelling-covid"
                  component={TravellingDuringCovid}
                />
                <Route exact path="/third-wave" component={ThirdWave} />
                <Route exact path="/policy" component={Policy} />
                <Route exact path="/knowvitals" component={KnowVitals} />
                <Route exact path="/adnetwork" component={AdNetwork} />
                <Route exact path="/temphome" component={TempHome} />

                <Route exact path="/search" component={Search} />
                <Route exact path="/redeem-credit" component={RedeemCredit} />
                <Route
                  path="/passes/:package_short_name"
                  component={PassesDetails}
                />

                <LoggedOutRoute exact path="/user/register">
                  <Register />
                </LoggedOutRoute>
                <LoggedOutRoute exact path="/user/forgot-password">
                  <ForgotPassword />
                </LoggedOutRoute>
                <LoggedOutRoute
                  exact
                  path={[
                    '/user/login',
                    ...[
                      '/dashboard',
                      '/buy-credits',
                      '/credit-history',
                      '/my-bookings',
                      '/profile',
                      '/vitals-history',
                      '/vitals-list',
                      '/report',
                      '/checkout',
                    ].map((item) => `/user${item}/login`),
                  ]}
                >
                  <Login />
                </LoggedOutRoute>

                <ProtectedRoute exact path="/user/dashboard">
                  <Dashboard />
                </ProtectedRoute>
                <ProtectedRoute exact path="/user/buy-credits">
                  <BuyCredit />
                </ProtectedRoute>
                <ProtectedRoute exact path="/user/credit-history">
                  <CreditHistory />
                </ProtectedRoute>
                <ProtectedRoute exact path="/user/my-bookings">
                  <Bookings />
                </ProtectedRoute>
                <ProtectedRoute exact path="/user/profile">
                  <Profile />
                </ProtectedRoute>
                <ProtectedRoute exact path="/user/vitals-history">
                  <HistoricData />
                </ProtectedRoute>
                <ProtectedRoute exact path="/user/vitals-list">
                  <VitalsList />
                </ProtectedRoute>
                <ProtectedRoute exact path="/user/report">
                  <Report />
                </ProtectedRoute>
                <ProtectedRoute exact path="/user/checkout">
                  <Checkout />
                </ProtectedRoute>

                <Redirect exact from="/user" to="/user/dashboard" />

                <Redirect exact from="/" to="/home" />

                <Route path="/">
                  <Page>
                    <div style={{ paddingTop: '120px' }}>
                      <Page404 />
                    </div>
                  </Page>
                </Route>
              </TransitionSwitch>
            </Suspense>
          </Initialize>
        </HandleError>
      </SnackBarHOC>
    </ThemeProvider>
  );
};

export default App;

const theme = createTheme(globalTheme);

import React, { useEffect, useRef } from 'react';
import { Drawer as MuiDrawer } from '@material-ui/core';
import { useLocation } from 'react-router-dom';

const Drawer = ({
  open = false,
  onClose = () => {},
  drawerChildren = <></>,
}) => {
  const { pathname } = useLocation();

  const openRef = useRef(false);
  const onCloseRef = useRef(() => {});

  useEffect(() => {
    openRef.current = open;
    onCloseRef.current = onClose;
  }, [open, onClose]);

  useEffect(() => {
    if (openRef.current) {
      onCloseRef.current();
    }
  }, [pathname]);

  return (
    <MuiDrawer open={open} onClose={onClose} variant="temporary">
      {drawerChildren}
    </MuiDrawer>
  );
};

export default Drawer;

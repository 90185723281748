import { profileDetailsActions } from '../reducers/profile-details-slice';
import { uiActions } from '../reducers/ui-slice';
import axios from '../../axios';
import URIS from '../../api';
import { USER_DETAILS, CREDITS_INFO } from '../../global-constants';

export const fetchCompleteUserDetails = ({ showLoader = false }) => {
  return async (dispatch) => {
    if (showLoader) {
      dispatch(
        uiActions.setLoading({
          networkFetching: true,
          loadingMessage: 'Fetching your details',
        }),
      );
    }
    dispatch(
      profileDetailsActions.setFetchingUserDetails({
        loading: true,
        errorMessage: null,
      }),
    );

    const userDetailsSavedInLocalStorage = localStorage.getItem(USER_DETAILS)
      ? JSON.parse(localStorage.getItem(USER_DETAILS))
      : null;

    try {
      const { data } = await axios.post(URIS.VIEW_USER_PROFILE, {});

      if (data.statusCode === 200 || data.statusCode === 201) {
        dispatch(profileDetailsActions.setUserDetails(data?.profile || {}));

        localStorage.setItem(
          USER_DETAILS,
          JSON.stringify({
            ...userDetailsSavedInLocalStorage,
            ...(data?.profile || {}),
          }),
        );

        dispatch(
          profileDetailsActions.setFetchingUserDetails({
            loading: false,
            errorMessage: null,
          }),
        );
      } else {
        throw new Error('something-went-wrong');
      }
    } catch (error) {
      dispatch(
        profileDetailsActions.setFetchingUserDetails({
          loading: false,
          errorMessage: "Couldn't fetch user data!",
        }),
      );
    }

    dispatch(
      uiActions.setLoading({
        networkFetching: false,
        loadingMessage: null,
      }),
    );
  };
};

export const fetchCreditsInfo = ({ fetchNewData = false }) => {
  return async (dispatch) => {
    dispatch(
      profileDetailsActions.setFetchingCalculatedCredits({
        loading: true,
        errorMessage: null,
      }),
    );

    const creditsInfoSavedInLocalStorage = localStorage.getItem(CREDITS_INFO)
      ? JSON.parse(localStorage.getItem(CREDITS_INFO))
      : null;

    if (
      creditsInfoSavedInLocalStorage?.total_credits_earned !== undefined &&
      !fetchNewData
    ) {
      dispatch(
        profileDetailsActions.setFetchingCalculatedCredits({
          loading: false,
          errorMessage: null,
        }),
      );

      dispatch(
        profileDetailsActions.setCalculatedCredits(
          creditsInfoSavedInLocalStorage,
        ),
      );
      return;
    }

    let data = null;

    try {
      data = (await axios.post(URIS.VIEW_ALL_CREDITS_INFO, {})).data;

      const {
        total_credits_earned = 0.0,
        current_credits = 0.0,
        redeemed_credits_service = 0.0,
        credits_purchased = 0.0,
        credits_rewarded = 0.0,
        per_credit_value = 0.0,
      } = data || {};

      const creditsInfoObj = {
        total_credits_earned,
        current_credits,
        redeemed_credits_service,
        credits_purchased,
        credits_rewarded,
        per_credit_value,
      };

      if (data.statusCode === 200 || data.statusCode === 201) {
        dispatch(profileDetailsActions.setCalculatedCredits(creditsInfoObj));

        localStorage.setItem(CREDITS_INFO, JSON.stringify(creditsInfoObj));
      }
    } catch (error) {
      const creditsInfoObj = {
        total_credits_earned: 0.0,
        current_credits: 0.0,
        redeemed_credits_service: 0.0,
        credits_purchased: 0.0,
        credits_rewarded: 0.0,
        per_credit_value: 0.0,
      };

      dispatch(profileDetailsActions.setCalculatedCredits(creditsInfoObj));

      localStorage.setItem(CREDITS_INFO, JSON.stringify(creditsInfoObj));
    }

    dispatch(
      profileDetailsActions.setFetchingCalculatedCredits({
        loading: false,
        errorMessage: null,
      }),
    );
  };
};

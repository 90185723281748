import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  useReducer,
} from 'react';
import {
  Box,
  Dialog,
  makeStyles,
  Slide,
  Typography,
  useMediaQuery,
  useTheme,
  Button,
} from '@material-ui/core';
import produce from 'immer';
import HelpIcon from '@material-ui/icons/Help';

import DialogLoaderSlide from '../components/Loader/DialogLoaderSlide';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const slideReducer = produce(
  (
    state = {
      slides: [],
      direction: [],
      curSlide: 0,
    },
    action,
  ) => {
    switch (action.type) {
      case 'ADD_A_SLIDE':
        state.slides[action.payload.index] = action.payload.slideComponent;
        state.direction[action.payload.index] = 'left';
        return;

      case 'ADD_MULTIPLE_SLIDES':
        if (action.payload) {
          let slidesLength = state.slides.length;

          action.payload.forEach((item, index) => {
            state.slides[slidesLength + index] = item;
            state.direction[slidesLength + index] = 'left';
          });

          slidesLength = 0;
        }
        return;

      case 'CHANGE_DIRECTION':
        state.direction[action.payload] =
          state.direction[action.payload] === 'left' ? 'right' : 'left';
        return;

      case 'JUMP_SLIDES':
        state.curSlide = action.payload || state.curSlide + 1;
        return;

      case 'RESET':
        state.slides = state.slides.slice(0, 1);
        state.direction = ['left'];
        state.curSlide = 0;
        return;

      default:
        return {
          slides: [],
          direction: [],
          curSlide: 0,
        };
    }
  },
);

const useDialog = ({
  confirmationTemplete = false,
  loaderTemplate = false,
} = {}) => {
  const classes = useStyles();
  const theme = useTheme();

  const matchesUp480px = useMediaQuery('(min-width: 480px)');
  const matchesUp500px = useMediaQuery('(min-width: 500px)');

  const [open, setOpen] = useState(false);
  const [minHeight, setMinHeight] = useState(null);
  const [content, setContent] = useState(null);
  const [icon, setIcon] = useState(null);
  const [title, setTitle] = useState(null);
  const [actions, setActions] = useState(null);
  const [confirmButtonText, setConfirmButtonText] = useState(null);
  const [cancelButtonText, setCancelButtonText] = useState(null);
  const [dialogProps, setDialogProps] = useState(null);
  const [autoCloseWhenButtonClicked, setAutoCloseWhenButtonClicked] =
    useState(true);
  const [autoCloseWhenBackdropClicked, setAutoCloseWhenBackdropClicked] =
    useState(true);

  const [slideState, dispatchSlideStateChanges] = useReducer(slideReducer, {
    slides: [],
    direction: [],
    curSlide: 0,
  });

  const resolveRef = useRef(null);
  const valuesRef = useRef(null);
  const onClickedButtonsRef = useRef(null);

  const updateSlides = useCallback((index) => {
    dispatchSlideStateChanges({ type: 'JUMP_SLIDES', action: index });
  }, []);

  const closeDialog = useCallback(() => {
    setOpen(false);
  }, []);

  useEffect(() => {
    return () => {
      if (open && !!resolveRef.current) {
        resolveRef.current(valuesRef.current, updateSlides);
        valuesRef.current = null;
        resolveRef.current = null;
      }
    };
  }, [open, updateSlides]);

  useEffect(() => {
    dispatchSlideStateChanges({
      type: 'ADD_A_SLIDE',
      payload: {
        index: 0,
        slideComponent: (
          <React.Fragment>
            <div style={{ flex: 1, display: 'flex', flexDirection: 'row' }}>
              {matchesUp500px && icon !== false && (
                <div
                  style={{
                    alignSelf: 'center',
                    marginRight: theme.typography.pxToRem(20),
                  }}
                >
                  {React.isValidElement(icon) ? (
                    icon
                  ) : (
                    <HelpIcon
                      style={{ fontSize: theme.typography.pxToRem(100) }}
                    />
                  )}
                </div>
              )}

              <div
                style={{ flex: 1, display: 'flex', flexDirection: 'column' }}
              >
                {React.isValidElement(title) ? (
                  title
                ) : (
                  <Typography
                    variant="h5"
                    style={{
                      fontWeight: 700,
                      display: 'block',
                      paddingBottom: theme.typography.pxToRem(12),
                    }}
                  >
                    {title || (!!confirmationTemplete ? 'Are you sure?' : null)}
                  </Typography>
                )}

                {React.isValidElement(content) ? (
                  content
                ) : !!confirmationTemplete ? (
                  <div style={{ height: 'fit-content' }}>
                    {content || 'Do you want to proceed?'}
                  </div>
                ) : !!loaderTemplate ? (
                  <DialogLoaderSlide loadMessage={content || 'Loading'} />
                ) : null}

                {React.isValidElement(actions) ? (
                  actions
                ) : !!confirmationTemplete ? (
                  <Box
                    className={classes.DialogActions}
                    style={{
                      alignSelf: matchesUp480px ? 'flex-end' : 'center',
                    }}
                  >
                    <div>
                      <Button
                        color="secondary"
                        variant="contained"
                        className={classes.DialogButtons}
                        onClick={() => {
                          valuesRef.current = 'cancel';

                          if (autoCloseWhenButtonClicked) {
                            setOpen(false);
                          }

                          if (!!onClickedButtonsRef.current) {
                            onClickedButtonsRef.current(
                              'cancel',
                              closeDialog,
                              updateSlides,
                              setAutoCloseWhenBackdropClicked.bind(null, false),
                              setAutoCloseWhenBackdropClicked.bind(null, true),
                            );
                            onClickedButtonsRef.current = null;
                          }
                        }}
                      >
                        {cancelButtonText || 'Cancel'}
                      </Button>
                    </div>
                    <div>
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.DialogButtons}
                        onClick={() => {
                          valuesRef.current = 'confirm';

                          if (autoCloseWhenButtonClicked) {
                            setOpen(false);
                          }

                          if (!!onClickedButtonsRef.current) {
                            onClickedButtonsRef.current(
                              'confirm',
                              closeDialog,
                              updateSlides,
                              setAutoCloseWhenBackdropClicked.bind(null, false),
                              setAutoCloseWhenBackdropClicked.bind(null, true),
                            );
                            onClickedButtonsRef.current = null;
                          }
                        }}
                      >
                        {confirmButtonText || 'Confirm'}
                      </Button>
                    </div>
                  </Box>
                ) : null}
              </div>
            </div>
          </React.Fragment>
        ),
      },
    });
  }, [
    actions,
    classes.DialogActions,
    classes.DialogButtons,
    classes.DialogElementsWrapper,
    confirmationTemplete,
    content,
    matchesUp480px,
    theme.typography,
    title,
    autoCloseWhenButtonClicked,
    closeDialog,
    updateSlides,
    classes.DialogContentWrapper,
    matchesUp500px,
    loaderTemplate,
    icon,
    cancelButtonText,
    confirmButtonText,
  ]);

  return {
    dialogComponent: (
      <Dialog
        open={open}
        onClose={() => {
          if (autoCloseWhenBackdropClicked) {
            setOpen(false);
          }
        }}
        TransitionComponent={Transition}
        TransitionProps={{
          onExited: () => dispatchSlideStateChanges({ type: 'RESET' }),
        }}
        {...(typeof dialogProps === 'object' ? dialogProps : {})}
        classes={{
          container: [
            classes.DialogContainer,
            dialogProps?.classes?.container || '',
          ].join(' '),
          paper: [classes.DialogPaper, dialogProps?.classes?.paper || ''].join(
            ' ',
          ),
        }}
      >
        <div
          className={classes.DialogElementsWrapper}
          style={minHeight ? { minHeight } : {}}
        >
          {slideState.slides.length === 1
            ? slideState.slides[0]
            : slideState.slides.map((item, index) => (
                <Slide
                  key={index}
                  in={index === slideState.curSlide}
                  appear={index === slideState.curSlide}
                  direction={slideState.direction[index]}
                  onEntered={() =>
                    dispatchSlideStateChanges({
                      type: 'CHANGE_DIRECTION',
                      payload: index,
                    })
                  }
                  mountOnEnter
                  unmountOnExit
                  timeout={{
                    enter: index === 0 ? 0 : 500,
                    appear: index === 0 ? 0 : 500,
                    exit: 500,
                  }}
                >
                  <div className={classes.DialogContentWrapper}>{item}</div>
                </Slide>
              ))}
        </div>
      </Dialog>
    ),
    openDialog: useCallback(
      (
        cb,
        {
          autoCloseWhenButtonClicked = true,
          autoCloseWhenBackdropClicked = true,
          icon,
          title,
          content,
          actions,
          confirmButtonText,
          cancelButtonText,
          dialogProps,
          slides,
          onClickButtons,
          minHeight = null,
        } = {},
      ) => {
        setDialogProps(dialogProps);
        setMinHeight(minHeight);
        setContent(
          React.isValidElement(content) || typeof content === 'string'
            ? content
            : typeof content === 'function'
            ? content({ closeDialog, updateSlides })
            : null,
        );
        setIcon(icon);
        setTitle(title);
        setActions(actions);
        setConfirmButtonText(confirmButtonText);
        setCancelButtonText(cancelButtonText);
        setOpen(true);
        setAutoCloseWhenButtonClicked(autoCloseWhenButtonClicked);
        setAutoCloseWhenBackdropClicked(autoCloseWhenBackdropClicked);
        dispatchSlideStateChanges({
          type: 'ADD_MULTIPLE_SLIDES',
          payload: slides ? [...slides] : null,
        });

        resolveRef.current = cb;
        onClickedButtonsRef.current = onClickButtons;

        return {
          updateSlides,
          blockBackdropClose: () => {
            setAutoCloseWhenBackdropClicked(false);
          },
          unblockBackdropClose: () => {
            setAutoCloseWhenBackdropClicked(true);
          },
        };
      },
      [updateSlides, closeDialog],
    ),
    closeDialog,
    isDialogOpen: open,
  };
};

export default useDialog;

const useStyles = makeStyles((theme) => ({
  DialogContainer: {
    alignItems: 'flex-end',
  },
  DialogPaper: {
    width: '100%',
    margin: 0,
    overflowX: 'hidden',
    borderRadius: 0,
    borderTopLeftRadius: theme.typography.pxToRem(20),
    borderTopRightRadius: theme.typography.pxToRem(20),
    maxWidth: theme.typography.pxToRem(600),
    padding: theme.spacing(
      theme.typography.pxToRem(18),
      theme.typography.pxToRem(24),
    ),
    minHeight: theme.typography.pxToRem(200),

    '@media only screen and (min-width: 400px)': {
      padding: theme.spacing(
        theme.typography.pxToRem(18),
        theme.typography.pxToRem(30),
      ),
    },
  },
  DialogElementsWrapper: {
    position: 'relative',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  DialogContentWrapper: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    height: '100%',
    width: '100%',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  DialogActions: {
    marginTop: 'auto',
    paddingTop: theme.typography.pxToRem(20),
    alignSelf: 'flex-end',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '100%',
    maxWidth: theme.typography.pxToRem(350),

    '& div': {
      flex: 1,
    },

    '& div:not(:last-child)': {
      paddingRight: theme.typography.pxToRem(16),

      '@media only screen and (min-width: 400px)': {
        paddingRight: theme.typography.pxToRem(22),
      },
    },
  },
  DialogButtons: {
    width: '100%',
    fontSize: theme.typography.pxToRem(14),

    '@media only screen and (min-width: 400px)': {
      fontSize: theme.typography.pxToRem(16),
    },
  },
}));

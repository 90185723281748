import React, { forwardRef } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

const ProtectedRoute = forwardRef(
  ({ path, component, render, children, ...props }, ref) => {
    const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

    return (
      <Route path={path} {...props} ref={ref}>
        {!isLoggedIn && <Redirect to={`${path}/login`} />}
        {children}
      </Route>
    );
  },
);

export default ProtectedRoute;

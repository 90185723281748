const URIS = {
  REGISTRATION: '/auth/sign-up',
  SIGN_IN_WITH_USERNAME_AND_PASSWORD: '/auth/sign-in',
  LOGOUT: '/auth/sign-out',
  BOOKINGS_LIST: '/patient/bookings',
  CREDITS_LIST: '/patient/transactions/credits',
  VIEW_USER_PROFILE: '/users/profile/view',
  USER_PROFILE_UPDATE: '/users/profile/update',
  VIEW_ALL_CREDITS_INFO: '/users/credits/view',
  INITIALIZE_PAYMENT: '/users/purchase/initialize',
  PAYMENT_GATEWAY_CHECK: '/users/purchase/check',
  FORGOT_PASSWORD_SEND_OTP: '/auth/generate-forgot-password-link',
  FORGOT_PASSWORD_CHANGE_PASS: '/auth/forgot-password-update-password',
  LIST_VITALS: '/vitals/list',
  PRODUCT_RECOMMENDATION_LIST: '/users/packages/recommend-list',
  CATEGORIES_LIST: '/public/list_categories',
  PACKAGES_LIST: '/public/list_packages',
  VIEW_PACKAGE_DESCRIPTION: '/public/view_package',
  PURCHASE_PACKAGES: '/users/packages/purchase',
  REPORT_CREATE: '/report/create',
  REPORT_DOWNLOAD: '/report/list',
};

let AVOID_ERROR_CHECKS = [];

const setAvoidErrorChecks = ({ url }) => {
  if (AVOID_ERROR_CHECKS.includes(url)) return;

  AVOID_ERROR_CHECKS.push(url);
};

const removeURLfromAvoidErrorChecks = ({ url }) => {
  AVOID_ERROR_CHECKS = AVOID_ERROR_CHECKS.filter((item) => item !== url);
};

export {
  AVOID_ERROR_CHECKS,
  setAvoidErrorChecks,
  removeURLfromAvoidErrorChecks,
};

export default URIS;

import React from 'react';
import { LinearProgress } from '@material-ui/core';

const RouteLoader = () => {
  return (
    <div
      style={{
        display: 'block',
        position: 'fixed',
        top: 80,
        right: 0,
        left: 0,
        width: '100%',
        zIndex: 999,
      }}
    >
      <LinearProgress variant="indeterminate" color="primary" />
    </div>
  );
};

export default RouteLoader;
